import React from "react";
import banner from "../assets/banner.svg";
import './Letter.css';

export default function Letter() {
    // TODO: notification to me

    return (
        <>
            <img src={banner} alt={'Banner.'} />
            <main>
                <h2>UPS, DA WÄR MAL EN KALENDER GSI</h2>
                <h1>HOI NURYA</h1>
                <div>
                    <p>Wow, du hesch de Kalender immerno. Mega schön treffed mir eus da wieder, oderso ähnlich zumindest.</p>
                    <p>Ich hoffe dir gahts guet, wo immer du au grad bisch.</p>
                    <p>Leider han ich de Speicherplatz vo de Bilder für die Wiehnachtsgschicht usversehe freigeh, drum de chlini Text da.</p>
                    <p>Eigentlich will ich dich au gar nöd zu lang uufhalte.</p>
                    <p>Will aber trotzdem nomal churz danke sege, für Alles. Denke no so oft a die Ziit wo ich han dörfe mit dir ha. Wirde dich für immer i mim Herz träge, bliib so wie du bisch und pass uf dich uf.</p>
                    <p>Du bisch öppis ganz bsundrigs. Wunderschön vo ine und vo usse!</p>
                </div>
                <h2>Liebi Grüess, Dominik</h2>
            </main>
        </>
    );


}