import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Home from "./home/Home";
import Letter from './letter/Letter';

export default function App() {

    // TODO: redirect always but when 1-24

    return (
        <Router>
            <Switch>
                <Route exact path={'/(1|2|3|4|5|6|7|8|9|10|11|12|13|14|15|16|17|18|19|20|21|22|23|24)'} component={Letter} />
                <Route path={'*'} component={Home} />
            </Switch>
        </Router>
    )
}